// App.js
import React, { useState, useEffect } from 'react';
import './webprofile.css';
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation, Pagination, EffectCoverflow ,Zoom} from 'swiper/modules'; 
import { Alert, Button, TextInput } from 'evergreen-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram,faWhatsapp, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope,faPhone } from '@fortawesome/free-solid-svg-icons'; // Email icon from solid

import 'swiper/css'; // This imports the core styles
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import goldeisimg from '../pages/jiffygoldies_o_web.png'
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
import axios from "axios";
import userimg from '../pages/users.png'
// Install the modules

const App = (data) => {
  
  const [fetcheddata, setData] = useState([]);
  const [bannerUrl, setBannerUrl] = useState('');
  const [scroll, setScroll] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [headerlogo,setheaderlogo]=useState('')
  const [section2header,setsection2header]=useState('')
  const [para2text,setpara2text]=useState('')
  const [section3image,setsection3image]=useState('')
  const [section4header,setsection4header]=useState('')
  const [section4para,setsection4para]=useState('')
  const [section5,setsection5]=useState([])
  const [section6,setsection6]=useState('')
  const [tagline,settagline]=useState('')
  const [email,setemail]=useState('')
  const [imageload,setimageload]=useState(false)
  const [buttontext,setButtontext]=useState('Send Invitation')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const [mailbody,setmailbody]=useState('')
  const [consumermailbody,setconsumermailbody]=useState('')
  const [favoritesmailbody,setfavoritesmailbody]=useState('')
  const [businessname, setbusinessname] = useState("");
const [businesslogo, setbusinesslogo] = useState("");
const [businessprofile, setbusinessprofile] = useState("");
const [busphone,setbusphone]=useState('')
const [successalert,setsuccessalert]=useState(false)
const { id } = useParams();
const [lang,countrycode,BID256] = id.split('_');
const [headerbackgroundcolor,setheaderbackgroundcolor]=useState('')
const [headeronscrollbackgroundcolor,setheaderonscrollbackgroundcolor]=useState('')
const [section1backgroundcolor,setsection1backgroundcolor]=useState('')
const [section1headerfont,setsection1headerfont]=useState('')
const [section1headerfontcolor,setsection1headerfontcolor]=useState('')
const [section1headerfontcoloronscroll,setsection1headerfontcoloronscroll]=useState('')
const [section1headerfontsize,setsection1headerfontsize]=useState('')
const [section2background,setsection2background]=useState('')
const [section2headerfont,setsection2headerfont]=useState('')
const [section2headerfontsize,setsection2headerfontsize]=useState('')
const [section2parafontsize,setsection2parafontsize]=useState('')
const [section2headerfontcolor,setsection2headerfontcolor]=useState('')
const [section2parafont,setsection2parafont ]=useState('')
const [section2parafontcolor,setsection2parafontcolor]=useState('')
const [section3backgroundcolor,setsection3backgroundcolor]=useState('')
const [section4headerfont,setsection4headerfont]=useState('')
const [section4headerfontcolor,setsection4headerfontcolor]=useState('')
const [section4headerfontsize,setsection4headerfontsize]=useState('')
const [section4parafont,setsection4parafont]=useState('')
const [section4paracolor,setsection4paracolor]=useState('')
const [section4parafontsize,setsection4parafontsize]=useState('')
const [section5backgroundcolor,setsection5backgroundcolor]=useState('')
const [section5headerfont,setsection5headerfont]=useState('')
const [section5headerfontsize,setsection5headerfontsize]=useState('')
const [section5headercolor,setsection5headercolor]=useState('')
const [section6headerfont,setsection6headerfont]=useState('')
const [section6headercolor,setsection6headercolor]=useState('')
const [section6headerfontsize,setsection6headerfontsize]=useState('')
const [section6parafont,setsection6parafont]=useState('')
const [section6parafontsize,setsection6parafontsize]=useState('')
const [section6paracolor,setsection6paracolor]=useState('')
const [section7,setsection7]=useState('')
const [section7background,setsection7background]=useState('')
const [section7font,setsection7font]=useState('')
const [section7fontcolor,setsection7fontcolor]=useState()
const [section7fontsize,setsection7fontsize]=useState('')
const [regheader,setregheader]=useState('')
const [regtext,setregtext]=useState('')
const [headerfontsize,setheaderfontsize]=useState('')
const [regheaderfontsize,setregheaderfontsize]=useState('')
const [regtextfontsize,setregtextfontsize]=useState('')
const [titlelogo,settitlelogo]=useState('')
const [title,settitle]=useState('')
const [webdescription,setwebdescription]=useState('')
const [referalcode,setreferalcode]=useState('')
const [referalcodeinput,setreferalcodeinput]=useState(false)
const [section8background,setsection8background]=useState('')
const [section8headercolor,setsection8headercolor]=useState('')
const [section8headerfont,setsection8headerfont]=useState('')
const [section8parafont,setsection8parafont]=useState('')
const [section8paracolor,setsection8paracolor]=useState('')
const [section8reftextcolor,setsection8reftextcolor]=useState('')
const [dynamicmanifest,setdynamicmanifest]=useState('')
const [section8invitetext,setsection8invitetext]=useState('')
const [section8invitefont,setsection8invitefont]=useState('')
const [section8invitefontsize,setsection8invitefontsize]=useState('')
const [section8invitetextcolor,setsection8invitetextcolor]=useState('')
const [section8footertext,setsection8footertext]=useState('')
const [section8footerfontsize,setsection8footerfontsize]=useState('')
const [section8footerfont,setsection8footerfont]=useState('')
const [section8footertextcolor,setsection8footertextcolor]=useState('')
const [section9,setsection9]=useState('')
const [busmail,setbusmail]=useState('')
function referalcodeinputview(){
  setreferalcodeinput(true)
}
function onemailchange(e){
    setErrormessage('')
  setemail(e.target.value)}
  function onreferallcodechange(e){
    setreferalcode(e.target.value)
  }
  function onemailend (e) {
    setErrormessage('')
    setemail(e.target.value)
    //validateEmail(e.target.value)
    
    if(email!=''){
        if(!validateEmail(e.target.value)){
          setErrormessage('Invalid')
        }
        else{
          setemail(e.target.value)
        }
       }
      }
  
  function  validateEmail (email){
    setErrormessage('')
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  
    }
  function submit() {
      
     if(email==''){
    setErrormessage('Email required')
     } 
     else if(errormessage==''){
      var posturl='';
      setButtontext("Sending")
      if(countrycode=='IN'){
        posturl='https://us-central1-jiffybook-india.cloudfunctions.net/Consumercommunityreg'
      }
      else if(countrycode=='EE'){
        posturl='https://us-central1-jiffybook-app.cloudfunctions.net/Consumercommunityreg'
         
      }
      axios({
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          //url:'https://us-central1-jiffybook-preprod.cloudfunctions.net/Consumercommunityreg',
          url:posturl,
          //url:'https://us-central1-jiffybook-india-preprod.cloudfunctions.net/Consumercommunityreg',
          data:{
            CMail:email,
            CPhone:countrycode+busphone,
            Bid:BID256,
            Bname:businessname,
            strBlogo:businesslogo,
            strBprofileimage:businessprofile,
            strBname:businessname,
            strCouponCode:referalcode.toLocaleUpperCase()

  
          },
        
        }).then(
          setTimeout(function(){
            setsuccessalert(true)
            
         // window.location.href='https://preprod-gallery-profile.web.app/Profile/'+BID256
            },2000),
            setTimeout(function(){
           setemail('')
           setButtontext("Send Invitation")
           setsuccessalert(false)
           setreferalcode('')
           setreferalcodeinput(false)
              },4000),
            console.log('posted',posturl))
            
  
      }
    }
  useEffect(() => {
   
  setData(data)
  console.log('thedatin',data)
    const fetchData = async (data) => {
      try {
        
  console.log('thedatn',fetcheddata)
      
        
        const combinedArray = Object.keys(fetcheddata.data.section5.Images).map(key => ({
            id: key,
            url: fetcheddata.data.section5.Images[key],
            text: fetcheddata.data.section5.ImageText[key]
          }));
        const swipearray=Object.keys(fetcheddata.data.section7.Images).map(key => ({
            id: key,
            url: fetcheddata.data.section7.Images[key],
            text: fetcheddata.data.section7.ImageText[key]
          }));
          
        setBannerUrl(fetcheddata.data.section1.Coverimage);
        setbusinessname(fetcheddata.data.busname)
        setbusinessprofile(fetcheddata.data.busprofile)
        setbusinesslogo(fetcheddata.data.buslogo)
          setheaderbackgroundcolor(fetcheddata.data.section1.Headerdefaultbackground)
          setheaderonscrollbackgroundcolor(fetcheddata.data.section1.Headerbackgroundonscroll)
          setsection1backgroundcolor(fetcheddata.data.section1.Backgroundcolor)
        setheaderlogo(fetcheddata.data.section1.Headerlogo) // Assuming result.bannerUrl contains the banner URL
        setsection2header(fetcheddata.data.section2.Heading2text)
        setpara2text(fetcheddata.data.section2.Para2text)
        setsection3image(fetcheddata.data.section3.Imageurl)
        setsection4header(fetcheddata.data.section4.Headertext)
        setsection4para(fetcheddata.data.section4.Paratext)
        setsection6(fetcheddata.data.section6)
        settagline(fetcheddata.data.section1.Tagline)
        setsection1headerfont(fetcheddata.data.section1.Font)
        setsection1headerfontcolor(fetcheddata.data.section1.Fontcolor)
        setsection1headerfontcoloronscroll(fetcheddata.data.section1.Fontcoloronscroll)
        setsection1headerfontsize(fetcheddata.data.section1.Fontsize)
        setsection2background(fetcheddata.data.section2.Backgroundcolor)
        setsection2headerfont(fetcheddata.data.section2.Headerfont)
        setsection2headerfontcolor(fetcheddata.data.section2.Headercolor)
        setsection2headerfontsize(fetcheddata.data.section2.Headerfontsize)
        setsection2parafontsize(fetcheddata.data.section2.Parafontsize)
        setsection2parafont(fetcheddata.data.section2.Parafont)
        setsection2parafontcolor(fetcheddata.data.section2.Paracolor)
        setsection3backgroundcolor(fetcheddata.data.section3.Backgroundcolor)
        setsection4headerfont(fetcheddata.data.section4.Headerfont)
        setsection4headerfontcolor(fetcheddata.data.section4.Headercolor)
        setsection4headerfontsize(fetcheddata.data.section4.Headerfontsize)
        setsection4parafont(fetcheddata.data.section4.Parafont)
        setsection4paracolor(fetcheddata.data.section4.Paracolor)
        setsection4parafontsize(fetcheddata.data.section4.Parafontsize)
        setsection5backgroundcolor(fetcheddata.data.section5.Backgroundcolor)
        setsection5headerfont(fetcheddata.data.section5.Headerfont)
        setsection5headerfontsize(fetcheddata.data.section5.Headerfontsize)
        setsection5headercolor(fetcheddata.data.section5.Headercolor)
        setsection6headerfont(fetcheddata.data.section6.Headerfont)
        setsection6headerfontsize(fetcheddata.data.section6.Headerfontsize)
        setsection6headercolor(fetcheddata.data.section6.Headercolor)
        setsection6parafont(fetcheddata.data.section6.Parafont)
        setsection6parafontsize(fetcheddata.data.section6.Parafontsize)
        setsection6paracolor(fetcheddata.data.section6.Paracolor)
        setsection7background(fetcheddata.data.section7.Backgroundcolor)
        setsection7font(fetcheddata.data.section7.Font)
        setsection7fontcolor(fetcheddata.data.section7.Fontcolor)
        setsection7fontsize(fetcheddata.data.section7.Fontsize)
        setregheader(fetcheddata.data.section8.Regheader)
        setregtext(fetcheddata.data.section8.Regtext)
        setheaderfontsize(fetcheddata.data.section1.Fontsize)
        setregheaderfontsize(fetcheddata.data.section8.Headerfontsize)
        setregtextfontsize(fetcheddata.data.section8.Parafontsize)
        settitlelogo(fetcheddata.data.section1.Titlelogo)
        settitle(fetcheddata.data.section1.Title)
        setsection8background(fetcheddata.data.section8.Backgroundcolor)
        setsection8reftextcolor(fetcheddata.data.section8.Referaltextcolor)
        setwebdescription(fetcheddata.data.section1.Description)
        setsection8headercolor(fetcheddata.data.section8.Headercolor)
        setsection8headerfont(fetcheddata.data.section8.Headerfont)
        setsection8paracolor(fetcheddata.data.section8.Paracolor)
        setsection8parafont(fetcheddata.data.section8.Parafont)
        setsection8invitetext(fetcheddata.data.section8.Invitetext)
        setsection8invitefontsize(fetcheddata.data.section8.Invitefontsize)
        setsection8invitefont(fetcheddata.data.section8.Invitefontfamily)
        setsection8invitetextcolor(fetcheddata.data.section8.Invitetextcolor)
        setsection8footertext(fetcheddata.data.section8.Footertext)
        setsection8footerfontsize(fetcheddata.data.section8.Footerfontsize)
        setsection8footerfont(fetcheddata.data.section8.Footerfontfamily)
        setsection8footertextcolor(fetcheddata.data.section8.Footertextcolor)
        setbusmail(fetcheddata.data.busmail)
        setsection9(fetcheddata.data.section9)
        setbusphone(fetcheddata.data.busphone)
        console.log(section1headerfont.replace(/^"(.*)"$/, '$1'))
        document.title=fetcheddata.data.section1.Title;
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = fetcheddata.data.section1.Titlelogo;
      document.getElementsByTagName('head')[0].appendChild(link);
      
      setdynamicmanifest({
        "short_name": fetcheddata.data.section1.Title,
        "name": fetcheddata.data.section1.Title,
        "icons": [
          {
            "src": fetcheddata.data.section1.Titlelogo,
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/x-icon"
          },
          {
            "src": fetcheddata.data.section1.Titlelogo,
            "type": "image/png",
            "sizes": "192x192"
          },
          {
            "src": fetcheddata.data.section1.Titlelogo,
            "type": "image/png",
            "sizes": "512x512"
          }
        ],
        "start_url": ".",
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff"
      })
      
      const ogImageMeta = document.querySelector("meta[property='og:image']");
  if (ogImageMeta) {
    
    ogImageMeta.setAttribute("content", fetcheddata.data.section1.Titlelogo);
  }
  const appleTouchIconLink = document.getElementById("apple-touch-icon");
  if (appleTouchIconLink) {
    appleTouchIconLink.setAttribute("href", fetcheddata.data.section1.Titlelogo);
  }
  const defaulticon=document.getElementById("icon");
  if (defaulticon) {
    defaulticon.setAttribute("href", fetcheddata.data.section1.Titlelogo);
  }
  
  document.getElementsByTagName('head')[0].appendChild(link);     
  if(swipearray){
                
        setsection7(swipearray.sort((a, b) => a.id.localeCompare(b.id)))
        
        console.log(section7)
        }
          if(combinedArray){
          setsection5(combinedArray.sort((a, b) => a.id.localeCompare(b.id)))
        console.log(section5)
      }
      } catch (error) {
        console.error('Error fetchingdata:', error);
      }
    };

    fetchData(fetcheddata);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [data,fetcheddata]);
  
    if (!data) {
      return <div>Loading...</div>;
    }
  return (
   
    <div>
         <head>
         <meta property="og:image" content={titlelogo} />
          <meta property="og:title" content={title} />
  <meta property="og:description" content={webdescription} />
 
         <link rel="icon" href={titlelogo} />
         <link rel="apple-touch-icon" href={titlelogo} />
         
    <link rel="manifest" href={new Blob([JSON.stringify(dynamicmanifest)], {
      type: 'application/json',
    })} />
         <link href='https://fonts.googleapis.com/css?family=Great Vibes' rel='stylesheet'></link>
         <link href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap" rel="stylesheet"></link>
<link href="https://fonts.googleapis.com/css2?family=Sacramento&display=swap" rel="stylesheet"></link>
      </head>
    <header className={`header ${isScrolled ? 'scrolled' : ''}`} 
    style={{backgroundColor:isScrolled?headeronscrollbackgroundcolor:headerbackgroundcolor,
    fontSize:headerfontsize,borderStyle:'none',height:90,fontWeight:'lighter'}}>
      <img src={headerlogo} alt="Logo" className="logo" />
      <h1 style={{fontWeight:'lighter',fontFamily:section1headerfont.replace(/^"(.*)"$/, '$1'),color:isScrolled?section1headerfontcoloronscroll:section1headerfontcolor,fontSize:section1headerfontsize
      }}>{tagline}</h1>
    </header>
    <div style={{backgroundColor:section2background}}>
    <div  style={{ //backgroundImage: `url(${bannerUrl})`,
    backgroundColor:section2background,width:'100%' }}>
     <img className="banner" src={bannerUrl?bannerUrl:null}></img>
    </div>
    </div>
    <div className="black-section" style={{backgroundColor:section2background,}}>
      <div className="black-section-text">
      <h1 style={{color:section2headerfontcolor,textAlign:'center',
      fontFamily:section2headerfont,fontSize:section2headerfontsize}}>{section2header}</h1>

        <p className='black-section-para'style={{textAlign:'center',fontSize:section2parafontsize,
          color:section2parafontcolor,fontFamily:section2parafont,}}>{para2text}</p>
        
      </div>
    </div>
    {section7 ? (
      <div className="swipercard-container" style={{backgroundColor:section7background,padding:20}}>
      <Swiper
      modules={[Autoplay, Navigation, Pagination, EffectCoverflow,Zoom]} // Register modules here
      effect="coverflow"
      grabCursor={false}
      centeredSlides={true}
      slidesPerView={3}  
      loop={true}
      spaceBetween={-100}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      zoom= {{
        maxRatio:3, // Adjust the maximum zoom ratio
      }}
      coverflowEffect={{
        rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
        slideShadows: true,
        centered: true
      }}
     // pagination={{ clickable: true }}
      >
        {section7.map(item => (
          <SwiperSlide key={item.id}>
             <div key={item.id} className="card-wrapper">
         <div className="swipercard">
           <img src={item.url} alt={`Image ${item.text}`} />
           <div className="swipertext-overlay">
          <h2 style={{fontSize:section7fontsize,fontFamily:section7font,color:section7fontcolor}}>{item.text}</h2>
        </div>
         </div>
       </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
      ) : null}
      <div className='section4wrap' style={{backgroundColor:section3backgroundcolor}}>
    <div  //style={{ backgroundImage: `url(${section3image})` }}
    >
      <img className="section4banner" src={section3image}></img>
    </div>
    <div className="section4" style={{backgroundColor:section3backgroundcolor}}>
      <div className="section4-text">
        <h1 style={{fontFamily:section4headerfont,color:section4headerfontcolor,fontSize:section4headerfontsize}}>{section4header}</h1>
        <p style={{fontFamily:section4parafont,color:section4paracolor,fontSize:section4parafontsize}}>{section4para}</p>
        
      </div>
    </div>
    </div>
    {section5!=[]?(
     <div className={`card-container ${section5.length <= 5 ? 'center' : ''}`} 
     style={{backgroundColor:section5backgroundcolor,
    }}>
     {section5.map(item => (
       <div key={item.id} className="card-wrapper">
         <div className="productcard"  >
           <img style={{height:'100%',borderRadius:8,}} src={item.url} alt={`Image ${item.text}`} />
         </div>
         <h2 className="card-text" 
         style={{fontFamily:section5headerfont,color:section5headercolor,fontSize:section5headerfontsize,
          //fontWeight:'bold'
         }}>{item.text}</h2>
       </div>
     ))}
   </div>):null}
   <div style={{ position: 'relative', width: '100%',backgroundColor:section8background }}>
  <img 
    className="section6" 
    src={section6.Image} 
    style={{ width: '100%', height: 'auto' }} 
    alt="Section Image"
  />
  
  <div 
    className="section6text" 
    style={{
      position: 'absolute', 
      bottom: '20px',  // Positioning 20px above the bottom
      left: '20%',    // Positioning 20px from the left edge
      color: section6headercolor,
      padding: '10px'  // Optional: Add padding around the text
    }}
  >
    <h2 style={{ fontFamily: section6headerfont, fontSize: section6headerfontsize,color:section6headercolor }}>
      {section6.Headertext}
    </h2>
    <p style={{ fontFamily: section6parafont, fontSize: section6parafontsize, color: section6paracolor }}>
      {section6.Paratext}
    </p>
  </div>
</div>

   
  <div className='registration' style={{backgroundColor:section8background}}>
  <img className='goldiesimg' src={goldeisimg}></img>
    < h2 className='regheader' style={{fontSize:regheaderfontsize,fontFamily:section8headerfont,color:section8headercolor}}>{regheader}
    </h2>
    <p className='regtext' style={{fontSize:regtextfontsize,fontFamily:section8parafont,color:section8paracolor}}>{regtext}</p>
  {successalert==true?(<Alert
    intent="success"
    title='Invitation sent'
    marginTop={10}
    marginLeft={'15%'}
    width={'65%'}
  />):null}
        {imageload==false?(
         
      <div className='inputs'>
        <div style={{flexDirection:'row',borderColor:'#e75600'}}>
      <TextInput
        value={email}
        onChange={onemailchange}
        onBlur={onemailend}
        placeholder={email?email:'Email'}
        style={{backgroundColor:'black',color:'white',borderColor:'#e75600'}}
      /> <br></br>
     
      </div>
      
    </div>):null} 

     {imageload==false&&referalcodeinput==true?(
         
         <div className='inputs'>
           <div style={{flexDirection:'row',borderColor:'#e75600'}}>
         <TextInput
           value={referalcode}
           onChange={onreferallcodechange}
           placeholder={referalcode?referalcode:'Referal code'}
           style={{backgroundColor:'black',color:'white',borderColor:'#e75600'}}
         /> <br></br>
        
         </div>
         
       </div>):(<div className='inputs' >
        <Button onClick={referalcodeinputview} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
       <span style={{color:'#e75600',fontSize:21}} > &#10753;&nbsp;</span>
       <span style={{color:'white',fontSize:14,color:section8reftextcolor}}>Add referral code?</span> 
       </Button>
       </div>)}
    {imageload==false?(
    <div style={{justifyContent:'center',alignItems:'center'}}>
         <Button className="submitbutton" style={{backgroundColor:'#e75600',color:'white',borderRadius:20,
        marginTop:15,fontSize:14,height:24,fontWeight:'bold',borderColor:'#e75600',width:270,}}
         onClick={submit}>{buttontext.toLocaleUpperCase()}
         <img id="weburl" style={{borderRadius:20}} src={userimg} className='userimage' ></img>
     
         </Button>
      
    </div>):null}
    {errormessage!=''?(
    <Alert
    intent="danger"
    title={errormessage}
    width={'65%'}
    fontWeight={'bold'}
    textAlign={'center'}
    marginTop={10}
  />):null}
  <div style={{marginTop:50}}>
  <h1 style={{ fontSize: section8invitefontsize, fontFamily: section8invitefont, color: section8invitetextcolor, 
    textAlign: "center" }}>
    {section8invitetext}
  </h1>
  
  <h5 style={{ textAlign: "center" }}>
    <a href="https://play.google.com/store/apps/details?id=com.indreamer.jiffybook">
      <img
        width="200"
        src="https://firebasestorage.googleapis.com/v0/b/jiffybook-public.appspot.com/o/jiffybook.indreamer.com%2Fplay-store-badge.png?alt=media&token=191b000c-08b5-4238-aa03-a8400755351d"
        alt="Play Store Badge"
      />
    </a>
    <br />
    <a href="https://apps.apple.com/app/id1514749994">
      <img
        width="200"
        src="https://firebasestorage.googleapis.com/v0/b/jiffybook-public.appspot.com/o/jiffybook.indreamer.com%2Fapp-store-badge.png?alt=media&token=28b3781e-4eff-4314-8919-dd485f4e9949"
        alt="App Store Badge"
      />
    </a>
  </h5>
</div>
<div style={{flexDirection:'row',display:'flex'}}>
  <div style={{ textAlign: 'left' ,display:'flex',flexDirection:'column',}}>
    <span style={{color:section9.Contacttextcolor,textAlign:'center',fontSize:section9.Contactfontsize,
      font:section9.Contactfont,fontWeight:'bold'
    }}>
      {section9.Contacttext?section9.Contacttext:'Contact'}</span>
    
    <div style={{flexDirection:'row',marginTop:5}}>
    {busphone?(<a href={"tel:"+busphone} >
  <FontAwesomeIcon icon={faPhone} className='iconstyle' />
  </a>):null}
    {busmail?(
  <a href={"mailto:"+busmail}>
    <FontAwesomeIcon icon={faEnvelope} className='iconstyle' />
  </a>):null}
  {section9.Whatsapp?(
    <a href={section9.Whatsapp}>
    <FontAwesomeIcon icon={faWhatsapp} className='iconstyle' />
  </a>
  ):null}
  
  </div>
  </div>
<div style={{ textAlign: 'left' ,display:'flex',flexDirection:'column',marginLeft:60}}>
  <span style={{color:section9.Socialmediatextcolor,font:section9.Socialmediafont,fontSize:section9.Socialmediatextfontsize,
    fontWeight:'bold',textAlign:'center'}}>{section9.Socialmediatext?section9.Socialmediatext:'Follow us on'}</span>
  <div style={{flexDirection:'row',marginTop:5}}>
    {section9.Facebook?(
      <a href={section9.Facebook} target="_blank" rel="noopener noreferrer" className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Facebookiconcolor}}>
        <FontAwesomeIcon icon={faFacebook} />
      </a>):null}
      {section9.Twitter?(
      <a href={section9.Twitter} target="_blank" rel="noopener noreferrer" className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Twittericoncolor}}>
        <FontAwesomeIcon icon={faTwitter} />
      </a>):null}
      {section9.Instagram?(
      <a href={section9.Instagram} target="_blank"  className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Instagramiconcolor}}>
        <FontAwesomeIcon icon={faInstagram} />
      </a>):null}
      {section9.Youtube?(
      <a href={section9.Youtube} target="_blank"  className='iconstyle'
      style={{Fontsize:section9.Iconsize,color:section9.Youtubeiconcolor}}>
        <FontAwesomeIcon icon={faYoutube} />
      </a>):null}
      </div>
    </div>
    </div>
 <footer style={{color:section8footertextcolor,fontSize:section8footerfontsize,
  fontFamily:section8footerfont,marginTop:25,marginBottom:10}}>{section8footertext}</footer> 
  </div>
  </div>
  );
};

export default App;
