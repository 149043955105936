
import React from 'react';
//import './App.css';
//import Navbar from './components/Navbar';
//import Navbar from './components/Navbarelements/index'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
    import Templaterenderer from './components/pages/Templaterenderer';
    import Subdomainrender from './components/pages/Subdomainrender';
    import Orderpayment from './components/pages/orderpayment'
    
    function App() {
      return (
    
          <Router>
        
          <Routes>
              <Route path='/:id' element={<Subdomainrender/>}/>
          </Routes>
          </Router>
  );
  }
    
  export default App;