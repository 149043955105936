import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Import core Swiper styles
import 'swiper/css/navigation';  // Import Navigation module styles
import 'swiper/css/pagination';  // Import Pagination module styles
import 'swiper/css/effect-coverflow';  // Import Coverflow effect styles

import './webprofile.css';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules'; 

const SwiperComponent = () => {
  const data = [
    { id: 1, url: 'https://via.placeholder.com/300', text: 'Slide 1' },
    { id: 2, url: 'https://via.placeholder.com/300', text: 'Slide 2' },
    { id: 3, url: 'https://via.placeholder.com/300', text: 'Slide 3' },
    { id: 4, url: 'https://via.placeholder.com/300', text: 'Slide 4' }
  ];
  useEffect(() => {
    const fetchData = async () => {
    const host = window.location.hostname;
    const subdomain = host.split('.')[0];
    console.log('subdomain',subdomain)
    const response=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getsubdomainprofiledata?parameter=${subdomain}`)
    const result = await response.json();
    console.log('subdatafetched',result)
  }
  fetchData();
})

  return (
    <div className="swiper-container">
      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow]} // Register modules here
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map(item => (
          <SwiperSlide key={item.id}>
            <div className="slide-content">
              <img src={item.url} alt={`Slide ${item.id}`} />
              <p>{item.text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
