//import { async } from "@firebase/util";
import React ,{useState,useEffect}from "react";
//import TextField from "@material-ui/core/TextField";
//import { Text } from '@react-ui-org/react-ui';
//import LazyLoad from 'react-lazyload';
import '../../Profile.css'
import { Alert, Button, TextInput } from 'evergreen-ui'
import Thanks from "./profilethanks";
import Slider from 'react-slick';
import Modal from 'react-modal';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
  //import { db } from "./firebase";
  import backbutton from '../pages/backbutton.png'
  import weburl from '../pages/kok.png'
  import userimg from '../pages/users.png'
  import blogo from '../pages/blogo.png'
  import ReactStarRatings from 'react-star-ratings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import axios from "axios";
//import { getFirestore, collection, getDocs,firestore,updateDoc,doc,getDoc} from 'firebase/firestore';
const Template0 = () => {
    const [businessname, setbusinessname] = useState("");
  const [businessweb, setbusinessweb] = useState("");
  const [textmessage, settextmessage] = useState([]);
  const [businesslogo, setbusinesslogo] = useState("");
  const [businessprofile, setbusinessprofile] = useState("");
  const [businesssignature, setbusinesssignature] = useState("");
  const [translationview,settranslationview]=useState(false)
  const [translationmessage,settranslationmessage]=useState("")
  const[imageload,setimageload]=useState(true)
  const { id } = useParams();
  const [lang,countrycode,BID256] = id.split('_');
  const [name, setName] = useState('')
  const [phone,setPhone]=useState('')
  const [buttontext,setButtontext]=useState('Send Invitation')
  const [errormessage,setErrormessage]=useState('')
  const [thanksview,setthanksview]=useState(false)
  const [category,setcategory]=useState('')
  const [subcategory,setsubcategory]=useState('')
  const [offers,setoffers]=useState('')
  const [address,setaddress]=useState('')
  const [totalrating,settotalrating]=useState('')
  const [averagerating,setaveragerating]=useState(0)
  const [ratingdata,setratingdata]=useState('')
  const [description,setdescription]=useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);
 const [selectedItem, setSelectedItem] = useState(null);
 const [successalert,setsuccessalert]=useState(false)
 const [referalcode,setreferalcode]=useState('')
 const [referalcodeinput,setreferalcodeinput]=useState(false)
 // const [offercontentjust,setoffercontentjust]=useState('center')
  const publicurl=process.env.REACT_APP_API_JIFFYBOOKPUBLIC_URL
  const showLoader = () => {
    return (
      <div className="bouncing-ball">
        
      
      <div className="bouncing-ball">
        
        </div>
      </div>
    );
  }
 
function onemailchange(e){
  setErrormessage('')
setName(e.target.value)}
function onemailend (e) {
  setErrormessage('')
  setName(e.target.value)
  //validateEmail(e.target.value)
  
  if(name!=''){
      if(!validateEmail(e.target.value)){
        setErrormessage('Invalid')
      }
      else{
        setName(e.target.value)
      }
     }
    }

function  validateEmail (email){
  setErrormessage('')
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

  }
function submit() {
    console.log(name,phone)
    //alert('Hello!');
   if(name==''){
  setErrormessage('Email required')
   } 
   else if(errormessage==''){
    var posturl='';
    setButtontext("Sending")
    if(countrycode=='IN'){
      posturl='https://us-central1-jiffybook-india.cloudfunctions.net/Consumercommunityreg'
    }
    else if(countrycode=='EE'){
      posturl='https://us-central1-jiffybook-app.cloudfunctions.net/Consumercommunityreg'
       
    }
    axios({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        //url:'https://us-central1-jiffybook-preprod.cloudfunctions.net/Consumercommunityreg',
        url:posturl,
        //url:'https://us-central1-jiffybook-india-preprod.cloudfunctions.net/Consumercommunityreg',
        data:{
            CMail:name,
            CPhone:countrycode+phone,
            Bid:BID256,
            Bname:businessname,
            strBlogo:businesslogo,
            strBprofileimage:businessprofile,
            strBname:businessname,
            strCouponCode:referalcode.toLocaleUpperCase()

        },
      
      }).then(
        setTimeout(function(){
          setsuccessalert(true)
          },2000),
          setTimeout(function(){
         setName('')
         setButtontext("Send Invitation")
         setsuccessalert(false)
         setreferalcode('')
         setreferalcodeinput(false)
            },4000),
          console.log('posted',posturl))

    }
  }
  function onreferallcodechange(e){
    setreferalcode(e.target.value)
  }
  function referalcodeinputview(){
    setreferalcodeinput(true)
  }
  function isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    console.log(s,businessweb)
    console.log('regexp',regexp.test(s))
    if(regexp.test(s)==false){
      var neweb='https://'+businessweb
      setbusinessweb(neweb)
      console.log('web',businessweb)
    }
    //return regexp.test(s);
 }
 const openModal = (item) => {
  setSelectedItem(item);
  console.log(selectedItem,countrycode)
  setModalIsOpen(true);
};

const closeModal = () => {
  setSelectedItem(null);
  setModalIsOpen(false);
};
 const fetchprofiledata = async (countrycode) => {
  console.log('incountry',countrycode)
  
  if(countrycode=='IN'){
    const response=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getbusinessprofiledata?parameter=${BID256}`)
    const result = await response.json();
    
    document.title=result.businessname;
    setimageload(false)
    setcategory(result.Categories)
    setsubcategory(result.Subcategories)
    setbusinessname(result.businessname)
    setbusinesslogo(result.blogo)
    setbusinessprofile(result.bprofile)
    setbusinessweb(result.businessweb)
    setoffers(result.Offers)
    setaddress(result.Address)
    setdescription(result.Description)
    settotalrating(result.Totalratings)
    setratingdata(result.Ratingdata)
    setaveragerating(Number(result.Ratingsum/result.Totalratings))
      console.log('profiledata',result,averagerating)
  }
  if(countrycode=='EE'){
  const response=await fetch(`https://us-central1-jiffybook-app.cloudfunctions.net/getbusinessprofiledata?parameter=${BID256}`)
  const result = await response.json();
  setimageload(false)
  document.title=result.businessname;
  setcategory(result.Categories)
  setsubcategory(result.Subcategories)
  setbusinessname(result.businessname)
    setbusinesslogo(result.blogo)
    setbusinessprofile(result.bprofile)
    setbusinessweb(result.businessweb)
  setoffers(result.Offers)
  setaddress(result.Address)
  setdescription(result.Description)
  
    settotalrating(result.Totalratings)
    setaveragerating(Number(result.Ratingsum/result.Totalratings))
    
    setratingdata(result.Ratingdata)
  console.log('ratingdata',result.Ratingdata,averagerating)
    console.log('profiledata',result)
  }
 }

useEffect(() => {
  fetchprofiledata(countrycode)
  
    ;
  }, []);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },2000);
  
setTimeout(function(){
  document.getElementById('weburl').style.visibility = "visible";
  },2000);
  setTimeout(function(){
    document.getElementById('logo').style.visibility = "visible";
    },2000);
 
    return (
  
      <div className="maindiv">
         <head>
         <meta property="og:image" content={businesslogo} />
          <meta property="og:title" content={businessname} />
 
         <link rel="icon" href={businesslogo} />
         <link rel="apple-touch-icon" href={businesslogo} />
      </head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
         <div >
        {thanksview==false?(
       <div className="content">
        
      {businessprofile?(
        <div className="wrapper">
            
               <img className="wrapper" onLoad={()=>setimageload(false)}
               src={businessprofile} 
               alt="business profile image" />
              
          </div>):null}
         
         <div>
             
               <img className="profilewrap" onLoad={()=>setimageload(false)}
               id="logo" style={{visibility:'hidden'}}
                src={businesslogo}
                alt="logo image" />
          </div>
          {imageload==true?(
            <div style={{marginLeft:"45%",marginTop:'20%'}}>
       <div className="bouncing-ball">
         {showLoader()}
         </div>
      </div>):null}
          <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        {businessname?(
          <div className='businessname'  style={{whiteSpace:'nowrap',display:'inline',marginLeft:3,fontFamily:'Lexend Deca'}}
          >
         {businessname}
         </div> 
        ):null}
         {businessweb!=''?(
         <div  style={{whiteSpace:'wrap', display:'inline',}}>
         <a  href={`${businessweb}`} target="_blank" style={{color:'black',textDecoration:'none',
      }}
      >
         <img id="weburl" style={{visibility:'hidden',borderRadius:20,}} src={weburl} className='weburl' ></img>
        </a>
        </div>
        ):null}
        
        </div>
        {address?(
        <div className="businessaddress" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
         {address}
         </div> ):null}
         {averagerating!=0?(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:'5px',marginBottom:'10px'}}>
        {/* Display the average rating using heart icon */}
        <ReactStarRatings
          rating={averagerating}
          starDimension="20px"
          starSpacing="8px"
          starRatedColor="#e75600" // Color of the heart icon
          starHoverColor="#e75600"
          starEmptyColor="lightgray"
          changeRating={() => {}} // Disable rating change
          numberOfStars={5} // Use only one heart icon
          name="rating"
          starRated={<FavoriteIcon fontSize="inherit" />} // Heart icon
        />
         <span style={{marginLeft:'8px',fontSize:14,marginTop:-2}}>({totalrating})</span>
      </div>):null}
        {category?(
        <div className="category-container">
          {category.map((category, index) => (
            <span key={index} className="category">{category}</span>
          ))}
        </div>):null}
        {subcategory?(
        <div className="category-container">
          {subcategory.map((category, index) => (
            <span key={index} className="subcategory">{category}</span>
          ))}
        </div>):null}
        {ratingdata!=''?(
        <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column',marginTop:'5px'}}>
          {ratingdata.map((rating, index) => (
            <div key={index}>
              {/* Display the rating name */}
              <div style={{  textAlign: 'center', marginRight: '10px',fontSize:'12px',marginTop:'10px' }}>{rating.id}</div>
              {/* Display the star ratings */}
              <div style={{ flexGrow: 1, textAlign: 'center' }}>
              <ReactStarRatings
                rating={rating.data.numRatingSum / rating.data.numTotalRatings}
                starDimension="10px"
                starSpacing="2px"
                starRatedColor="#e75600"
                starHoverColor="#e75600"
                starEmptyColor="lightgray"
                changeRating={() => {}} // Disable rating change
                numberOfStars={5}
                name={`rating-${index}`}
                
              />
              </div>
            </div>
          ))}
        </div>):null}
      <div className="description">{description}</div>
       
        {offers?(
        
         
          <div
             style={{
               display: 'flex',
               padding: '10px 10px',
               overflowX: 'auto',
               //minWidth: '50%',
               height: '20%',
               //borderRadius: '10px',
               justifyContent:offers.length!=1?'space-between':'center',
              
             }}
           >
             {offers.map((item, index) => (
              <div key={index} 
              //onClick={() => openModal(item)}
              style={{borderRadius:'10px',minWidth:'50%',maxWidth:'80%',
              //minHeight:'250px',maxHeight:'350px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',margin:'10px',
              }}>
               {item.strOfferDesc?(
                <div  style={{marginLeft:'15px',marginTop:'15px',fontSize:'12px'}}>
                 {item.strOfferDesc}
                
                 </div>):<div style={{marginLeft:'15px',marginTop:'15px',fontSize:'12px'}}>&nbsp;</div>}
               <img
                 src={item.strImage}
               
                 style={{ width: '100%', height: '85%', objectFit: 'contain',
               // marginTop:'10px',
              // marginRight:'5%', marginLeft: '5%' 
              }}
               />
               
               </div>
             ))}
      
        </div>):null}
          {successalert==true?(<Alert
      intent="success"
      title='Invitation sent. Check email to accept'
      marginTop={10}
      marginLeft={'15%'}
      width={'65%'}
    />):null}
          {businessname?(
           
        <div className='inputs'>
          <div style={{flexDirection:'row'}}>
        <TextInput
          value={name}
          onChange={onemailchange}
          onBlur={onemailend}
          placeholder={name?name:'Email'}
        /> <br></br>
       
        </div>
        
      </div>):null} {imageload==false&&referalcodeinput==true?(
         
         <div className='inputs'>
           <div style={{flexDirection:'row',borderColor:'#e75600'}}>
         <TextInput
           value={referalcode}
           onChange={onreferallcodechange}
           placeholder={referalcode?referalcode:'Referal code'}
         /> <br></br>
        
         </div>
         
       </div>):(<div className='inputs' >
        {businessname?(
        <Button onClick={referalcodeinputview} style={{backgroundColor:'transparent',borderColor:'transparent'}}>
       <span style={{color:'#e75600',fontSize:14}} > &#10753; &nbsp;</span>
       <span style={{fontSize:14}}>Add referral code?</span> 
       </Button>):null}
       </div>)}
      {businessname?(
      <div style={{marginBottom:80}}>
          <Button className="joinbutton" style={{backgroundColor:'#e75600',color:'white',borderRadius:20,
          marginTop:15,fontSize:14,height:24,fontWeight:'bold',}}
           onClick={submit}>{buttontext.toLocaleUpperCase()}
           <img id="weburl" style={{borderRadius:20}} src={userimg} className='userimage' ></img>
       
           </Button>
        
      </div>):null}
      {errormessage!=''?(
      <Alert
      intent="danger"
      title={errormessage}
      marginTop={10}
      marginLeft={'15%'}
      width={'65%'}
      marginBottom={20}
    />):null}
    
          
          </div>):null}       
      </div>
      </div>
    );
};
  
export default Template0;